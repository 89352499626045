import { FC, ReactNode, Suspense } from "react";
import { Loading } from "antd-mobile";
import { Navigate } from "react-router-dom";
import { useAuth, AuthContextProps } from "./AuthContext";

type WrapperRouteProps = {
    auth: boolean;
    element: ReactNode;
    title?: string;
}

const  AuthTitleWrapper:FC<WrapperRouteProps> = function({ title, auth, element }) {
    const { initInfo } = useAuth() as AuthContextProps;
    if(title) document.title = title;
    if(!auth || !initInfo) return <Suspense fallback={<Loading />}>{element}</Suspense>;

    return initInfo?.token
        ? <Suspense fallback={<Loading />}>{element}</Suspense>
        : <Navigate to={{ pathname: `/login` }} />;
}

export default AuthTitleWrapper;
