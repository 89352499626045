import { ReactNode, createContext, useContext, useState } from "react";
import { useMount } from "ahooks";
import { IUser } from "../types/types";


type ArticleTrigger = 'home' | 'fav' | 'todo' | 'viewed';

type QueryCondition = {
    trigger: ArticleTrigger;
    where?: { categoryId?: number, keyword?: string }
};

export type InitInfo = {
    token: string,
    userId: number,
    userInfo: IUser
};
export type AuthContextProps = {
    initInfo: InitInfo,
    lastQuery: QueryCondition,
    setInitInfo: (p: null | Partial<InitInfo>) => void,
    setLastQuery: (p: null | Partial<QueryCondition>) => void,
};
const AuthContext = createContext<AuthContextProps | {}>({});

export default function AuthBizProvider({ children }: { children: ReactNode }) {
    const [initInfo, setInitInfo] = useState<InitInfo | null>(null);
    const [lastQuery, setLastQuery]= useState<QueryCondition | null>(null);

    useMount(() => {
        const localLastQuery = localStorage.getItem('lastQuery');
        const userId = localStorage.getItem('userId');
        const userInfo = localStorage.getItem('userInfo');
        const token = localStorage.getItem('TOKEN');
        const localInitInfo = {
            userId: Number(userId),
            userInfo: userInfo ? JSON.parse(userInfo) : {},
            token: token ? token : '',
        };
        setLastQuery(localLastQuery ? JSON.parse(localLastQuery) : {});
        setInitInfo(localInitInfo);
    });

    const store = { initInfo, setInitInfo, lastQuery, setLastQuery };
    return <AuthContext.Provider value={store} children={children} />;
}

export function useAuth() {
    return useContext(AuthContext);
}