import { lazy } from 'react';
import { Navigate, useRoutes, BrowserRouter } from 'react-router-dom';
import AuthTitleWrapper from './AuthWrapper';
import AuthBizProvider from './AuthContext';
import Layout from '../views/Layout';

const Home = lazy(() => import('../views/Home/Home'));
const List = lazy(() => import('../views/List/List'));
const Detail = lazy(() => import('../views/Detail/Detail'));
const Mine = lazy(() => import('../views/Mine/Mine'));
const Login = lazy(() => import('../views/Login/Login'));
const Feedback = lazy(() => import('../views/Feedback/Feedback'));

const shouldAuthItems = [
  {
    path: '/home',
    title: '首页',
    element: <Home />
  },
  {
    path: '/list',
    title: "待看｜收藏｜已读",
    element: <List />
  },
  {
    path: '/mine',
    title: "我的",
    element: <Mine />
  },
  {
    path: '/detail',
    title: "详情页",
    element: <Detail />
  },
  {
    path: '/feedback',
    title: "反馈",
    element: <Feedback />
  },
].map(({path, ...others}) => ({ path, element: <AuthTitleWrapper auth {...others} /> }));

const allRoutesConf = [
  {
    path: '/login',
    element: (
      <AuthTitleWrapper 
        element={<Login />}
        title="登陆页"
        auth={false}
      />
    ),
  },
  {
    path: '/',
    element: (
      <AuthTitleWrapper 
        element={<Layout />}
        auth={false}
      />
    ),
    children: [
      { index: true, element: <Navigate to="/home" /> },
      ...shouldAuthItems
    ] 
  },
];

function RouteItems() {
  return useRoutes(allRoutesConf);
}

function BizRoutes() {
  return (
    <AuthBizProvider>
      <BrowserRouter>
        <RouteItems />
      </BrowserRouter>
    </AuthBizProvider>
  );
}
export default BizRoutes;
