import { Outlet } from 'react-router-dom';
import styles from './index.module.scss';

export default function Layout() {
  return (
    <div className={styles['page-content']}>
      <Outlet/>
    </div>
  )
}
